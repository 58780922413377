/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { CaptchaStoreState } from './captcha-store.state';
import { CaptchaActionTypes } from './captcha-store.types';

export type CaptchaStoreReducer = (
  state: CaptchaStoreState,
  action: StoreActions
) => CaptchaStoreState;

export const captchaStoreReducer: CaptchaStoreReducer = (state, action) => {
  switch (action?.type) {
    case CaptchaActionTypes.SetIsCaptchaVisible:
      return {
        ...state,
        isCaptchaVisible: action.payload,
      };

    case CaptchaActionTypes.SetCaptchaToken:
      return {
        ...state,
        captchaToken: action.payload,
      };

    default:
      return state;
  }
};
