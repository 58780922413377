export const PlatformType = 'WEB';

export const FormIFLGetPricesFull = 'formIFLGetPricesFull';

export enum AuthEndpoints {
  CONFIRMAUTH = '/v3/user/confirm-authentication',
  REFRESH = '/v1/user/refresh-token',
  AUTH = '/v1/user/authorization',
  REG = '/v1/user/registration',
  INIT_ORDER = '/v1/payment/init-order',
  AUTHENTICATION = '/v1/user/authentication',
}

export enum SubmitEndpoints {
  SPORT = '/v3/subscription/submit/sport',
  MITE = '/v3/subscription/submit/antimite',
  OTHER = '/v1/subscription/submit',
}

export enum ApiEndpoints {
  GET_INSURANCE_PRODUCT = '/v1/references/get-insurance-product',
  GET_PETS_INFO = '/v1/references/get-pets-info',
  INSURANCE_LIMIT_START = '/v3/references/insurance-limit-start',
  GET_PRICES = '/v1/subscription/get-prices',
  INSURANCE_PROGRAMS = '/v4/references/insurance-programs',
  GET_INSURANCE_PROGRAMS_PRICES = '/v3/subscription/get-insurance-programs-prices',
}

export enum MethodApi {
  GET = 'get',
  POST = 'post',
}
