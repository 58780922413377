import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Product, miteStepsRoute } from '@src/constants';
import { useRequest } from '@src/hooks';
import { AuthActionTypes, CaptchaActionTypes, Store } from '@src/store';
import { phoneDisplayValueCasting } from '@src/utils';

interface Params {
  phone?: string;
  authenticationHandler?: () => void;
  authenticationErrorHandler?: (message: string) => void;
}

export const useAuthentication = ({
  phone,
  authenticationHandler,
  authenticationErrorHandler,
}: Params) => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { agentLogin, selectedProduct },
      stateCaptcha: { captchaToken },
      stateAuthFlow: { authStep },
    },
    dispatch,
  } = useContext(Store);

  const navigate = useNavigate();

  const { isLoading, error, res, refetch } = useRequest(
    'identificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber: phone,
      },
      ...(agentLogin && { agentLogin }),
    },
    [phone, agentLogin],
    true,
    undefined,
    {
      'X-URK': captchaToken,
      'X-TIMESTAMP': new Date().getTime(),
    }
  );

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: CaptchaActionTypes.SetCaptchaToken,
        payload: undefined,
      });
      dispatch({
        type: CaptchaActionTypes.SetIsCaptchaVisible,
        payload: false,
      });
      authenticationHandler && authenticationHandler();

      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: res?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: res?.authenticationToken,
      });

      if (phone) {
        dispatch({
          type: AuthActionTypes.SetPhoneNumber,
          payload: phone,
        });
        dispatch({
          type: AuthActionTypes.SetDisplayPhoneNumber,
          payload: phoneDisplayValueCasting(phone!),
        });
      }
    }
  }, [
    res?.confirmationId,
    res?.authenticationToken,
    isLoading,
    phone,
    authenticationHandler,
  ]);

  useEffect(() => {
    if (error) {
      dispatch({
        type: CaptchaActionTypes.SetCaptchaToken,
        payload: undefined,
      });
      dispatch({
        type: CaptchaActionTypes.SetIsCaptchaVisible,
        payload: false,
      });
      const isLetsTakeBreakError =
        error?.response?.data?.code === 'RATE_LIMIT_EXCEEDED' &&
        !error?.response?.data?.unlockingTime &&
        error?.response?.status === 400;

      if (isLetsTakeBreakError) {
        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });

        dispatch({
          type: CaptchaActionTypes.SetIsCaptchaVisible,
          payload: false,
        });

        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: t('AUTH:hints.letsTakeBreak'),
            subtitle: t('AUTH:labels.tryAgainLater'),
            refRoute:
              selectedProduct === Product.MITE
                ? miteStepsRoute
                : `/${selectedProduct}`, //TODO: когда появится нулевой шаг - переделать роут для продуктов с нулевым шагом
            step: authStep,
          },
        });

        navigate('/authorize-fail');

        return;
      }

      const isRateLimitExceededError =
        error?.response?.data?.code === 'RATE_LIMIT_EXCEEDED' &&
        error?.response?.data?.unlockingTime;

      const msg = !!error
        ? isRateLimitExceededError
          ? t('AUTH:errors.authorizationLimitExceeded')
          : t('COMMON:errors.sendingErrorTryAgain')
        : '';

      authenticationErrorHandler && authenticationErrorHandler(msg);

      if (isRateLimitExceededError) {
        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });
      }
    }
  }, [error, authenticationErrorHandler]);

  return { refetch, isLoading };
};
