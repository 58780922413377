/* eslint-disable prettier/prettier */

/* eslint-disable indent */
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { PageBackButton } from '@src/common-components/button';
import { PageContent } from '@src/common-components/container';
import { PageSubTitle, PageTitle } from '@src/common-components/headers';
import { ArrowLeftIcon2, Skeleton } from '@src/components';
import {
  AnalyticsType,
  sendAnalyticEvent,
  sendUserIdToAnalytics,
} from '@src/components/web-analytic';
import {
  ClientCategory,
  Product,
  analyticEvents,
  salesNinjaEvents,
  vipClientAvailableProductsList,
} from '@src/constants';
import { Otp } from '@src/features';
import {
  useHandlePressKey,
  useRegistrationRequest,
  useRequest,
} from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import {
  AuthActionTypes,
  CaptchaActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { KeyCode, Status } from '@src/types';
import { handleAnalyticsEvents } from '@src/utils';

const expirePeriod = 30;
const otpLimit = 5;
const resetErrorMs = 2000;

export const AuthorizationPage: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateAuth: {
        confirmationId,
        displayPhoneNumber,
        authenticationToken,
        phoneNumber,
      },
      stateUser: {
        selectedProduct,
        cachedPhoneNumber,
        profile,
        isPureSubscription,
        clientCategoryFromUrl,
        userRegistrationData,
        preset,
        agentLogin,
      },
      stateAuthFlow: { sepScrAuth, authStep },
      stateCaptcha: { captchaToken },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [smsCode, setSmsCode] = useState<string>('');
  const [expiredTime, setExpiredTime] = useState<number>(
    Date.now() / 1000 + expirePeriod
  );
  const is003Exists = useMemo(
    () =>
      userRegistrationData?.userPolicies?.some(({ code }) => code === '003'),
    [userRegistrationData?.userPolicies]
  );
  const isRequiredDataIncomplete = useMemo(
    () =>
      !userRegistrationData?.firstName ||
      !userRegistrationData?.lastName ||
      !userRegistrationData?.birthDay ||
      !userRegistrationData?.userPolicies ||
      !is003Exists,
    [
      userRegistrationData?.firstName,
      userRegistrationData?.lastName,
      userRegistrationData?.birthDay,
      userRegistrationData?.userPolicies,
      is003Exists,
    ]
  );
  const isNeedToUnblockVip =
    selectedProduct === Product.HOUSE &&
    clientCategoryFromUrl === ClientCategory.VIP &&
    preset;

  const { isLoading, error, res, refetch } = useRequest(
    'authRequest',
    'post',
    '/v3/user/confirm-authentication',
    {
      code: smsCode,
      confirmationId,
    },
    [
      phoneNumber,
      cachedPhoneNumber,
      confirmationId,
      authenticationToken,
      smsCode,
    ],
    true,
    authenticationToken
  );

  const { registrationRefetch, isRegistrationLoading } =
    useRegistrationRequest();

  const {
    isLoading: isResendLoading,
    error: resendError,
    res: resendRes,
    refetch: resendRefetch,
  } = useRequest(
    'resendIdentificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber,
      },
      ...(agentLogin && { agentLogin }),
    },
    [phoneNumber, agentLogin],
    true,
    undefined,
    {
      'X-URK': captchaToken,
      'X-TIMESTAMP': new Date().getTime(),
    }
  );

  useEffect(() => {
    if (preset) {
      sendAnalyticEvent(analyticEvents.requestPresetNewOtp);
    } else {
      sendAnalyticEvent(analyticEvents.requestNewOtp);
    }
  }, []);

  useEffect(() => {
    if (smsCode.length === otpLimit) {
      submitPage();
    }
  }, [smsCode]);

  useEffect(() => {
    if (!isResendLoading && resendRes) {
      dispatch({
        type: CaptchaActionTypes.SetCaptchaToken,
        payload: undefined,
      });
      dispatch({
        type: CaptchaActionTypes.SetIsCaptchaVisible,
        payload: false,
      });
      setExpiredTime(Date.now() / 1000 + expirePeriod);
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: resendRes?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: resendRes?.authenticationToken,
      });
    }
  }, [resendRes]);

  useEffect(() => {
    if (!isLoading && res) {
      const { accessToken, csrfToken, refreshToken, isVip, userId, name } = res;
      if (csrfToken) {
        localStorage.setItem(
          'csrfToken',
          csrfToken
        );
      }
      dispatch({
        type: AuthActionTypes.SetAuthTokens,
        payload: {
          authorization: {
            accessToken,
            refreshToken,
          },
          isVip,
        },
      });

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: {
          ...profile,
          profile: {
            ...profile?.profile,
            firstName: name,
            userId,
          },
        },
      });

      sendUserIdToAnalytics(userId);
      localStorage.setItem('userId', userId);

      sendAnalyticEvent(analyticEvents.enterOtpSuccess);

      if (name) {
        let myTrackerLogin = analyticEvents.completeLogin;

        if (selectedProduct) {
          switch (selectedProduct) {
            case Product.SPORT:
              myTrackerLogin = analyticEvents.sportCompleteLogin;
              sendAnalyticEvent(
                analyticEvents.sportSignupCompleted,
                { sz: 'auth_signup_sport_completed' },
                AnalyticsType.adriver
              );
              sendAnalyticEvent(
                salesNinjaEvents.authSportSignupCompleted,
                null,
                AnalyticsType.salesNinja
              );
              break;
            case Product.APARTMENT:
              myTrackerLogin = analyticEvents.flatCompleteLogin;
              sendAnalyticEvent(
                analyticEvents.flatSignupCompleted,
                undefined,
                AnalyticsType.adriver
              );
              sendAnalyticEvent(
                salesNinjaEvents.authFlatSignupCompleted,
                null,
                AnalyticsType.salesNinja
              );
              break;
            case Product.HOUSE:
              sendAnalyticEvent(
                salesNinjaEvents.authHouseSignupCompleted,
                null,
                AnalyticsType.salesNinja
              );
              break;
            case Product.NS:
              sendAnalyticEvent(
                salesNinjaEvents.authNSSignupCompleted,
                null,
                AnalyticsType.salesNinja
              );
              break;
            case Product.MITE:
              myTrackerLogin = analyticEvents.antimiteCompleteLogin;
              sendAnalyticEvent(
                analyticEvents.miteSignupCompleted,
                { sz: 'auth_signup_mite_completed' },
                AnalyticsType.adriver
              );
              sendAnalyticEvent(
                salesNinjaEvents.authMiteSignupCompleted,
                null,
                AnalyticsType.salesNinja
              );
              break;
            case Product.PETS:
              myTrackerLogin = analyticEvents.petCompleteLogin;
              sendAnalyticEvent(
                analyticEvents.petsSignupCompleted,
                undefined,
                AnalyticsType.adriver
              );
              sendAnalyticEvent(
                salesNinjaEvents.authPetSignupCompleted,
                null,
                AnalyticsType.salesNinja
              );
              break;
          }
        }

        sendAnalyticEvent(myTrackerLogin, {}, AnalyticsType.myTracker);

        !!selectedProduct &&
          handleAnalyticsEvents.handleSignupComplete(selectedProduct, !!preset);

        if (preset) {
          sendAnalyticEvent(analyticEvents.signupPresetCompleted);
        } else {
          if (isVip) {
            localStorage.setItem('clientCategory', ClientCategory.VIP);
            sendAnalyticEvent(analyticEvents.signupCompleted, {
              registration: 'n',
              VIP: 'y',
            });
          } else {
            localStorage.setItem('clientCategory', ClientCategory.STANDART);
            sendAnalyticEvent(analyticEvents.signupCompleted, {
              registration: 'n',
            });
          }
        }
      }
    }
  }, [isLoading, res]);

  const handleKeyPressEnter = () => {
    if (smsCode.length === otpLimit) {
      submitPage();
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const submitPage = () => {
    refetch().then(async ({ status, data }) => {
      if (status === 'success') {
        const { accessToken, refreshToken, isVip, userId, name, csrfToken } = data?.data;
        if (csrfToken) {
          localStorage.setItem(
            'csrfToken',
            csrfToken
          );
        }
        dispatch({
          type: AuthActionTypes.SetAuthTokens,
          payload: {
            authorization: {
              accessToken,
              refreshToken,
            },
            isVip,
          },
        });

        dispatch({
          type: UserActionTypes.SetProfile,
          payload: {
            ...profile,
            profile: {
              ...profile?.profile,
              firstName: name,
              userId,
            },
          },
        });

        if (
          isVip &&
          !vipClientAvailableProductsList.includes(selectedProduct || '')
        ) {
          if (isNeedToUnblockVip) {
            navigate(selectedProduct ? `/${selectedProduct}` : '/');
          } else {
            navigate('/vip');
          }
        } else if (!name && isPureSubscription) {
          if (isRequiredDataIncomplete) {
            navigate('/personal-info');
          } else {
            await registrationRefetch();
            navigate(`/${selectedProduct}`);
          }
        } else {
          const pathToSelectedProduct =
            selectedProduct === Product.MITE
              ? '/mite-steps'
              : selectedProduct
              ? `/${selectedProduct}`
              : '/';

          const path =
            (sepScrAuth && authStep > 0) || locationState?.isUnauthorized
              ? locationState?.nextRoute
              : pathToSelectedProduct;

          if (locationState?.currentStep) {
            dispatch({
              type: WizardActionTypes.SetCurrentStep,
              payload: locationState?.currentStep,
            });
          }

          navigate(path);
        }
      }
    });
  };

  const handleOnChange = (e: string) => {
    setSmsCode(e);
  };

  const [isResendSubmited, setIsResendSubmited] = useState(false);

  const handleResend = useCallback(() => {
    setSmsCode('');
    dispatch({
      type: CaptchaActionTypes.SetIsCaptchaVisible,
      payload: true,
    });
    setIsResendSubmited(true);
  }, []);

  useEffect(() => {
    if (captchaToken && isResendSubmited) {
      setIsResendSubmited(false);
      resendRefetch();
    }
  }, [captchaToken, isResendSubmited]);

  const goBack = () => {
    dispatch({
      type: AuthActionTypes.SetConfirmationId,
      payload: undefined,
    });

    dispatch({
      type: AuthActionTypes.SetAuthenticationToken,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetCachedPhoneNumber,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetAuthenticationRefetchUid,
      payload: uuidv4(),
    });

    navigate('/login');
  };

  const status: Status = {
    status: error && error?.code ? 'error' : 'default',
    message: error && error?.code ? t('AUTH:errors.incorrectCode') || '' : '', // TODO: очищать ошибку когда снова начинают набирать и сделать разеый текст для разных ошибок
  };

  useEffect(() => {
    if (error) {
      if (preset) {
        sendAnalyticEvent(analyticEvents.enterPresetOtpFail);
      } else {
        sendAnalyticEvent(analyticEvents.enterOtpFail);
      }

      const e = error?.response?.status;

      if (e === 400) {
        if (error?.response?.data?.code === 'RATE_LIMIT_EXCEEDED') {
          dispatch({
            type: AuthActionTypes.SetConfirmationId,
            payload: undefined,
          });

          dispatch({
            type: AuthActionTypes.SetAuthenticationToken,
            payload: undefined,
          });

          dispatch({
            type: UserActionTypes.SetCachedPhoneNumber,
            payload: undefined,
          });

          dispatch({
            type: UserActionTypes.SetAuthenticationRefetchUid,
            payload: uuidv4(),
          });

          if (error?.response?.data?.unlockingTime) {
            navigate('/login', {
              state: {
                errorMessage: 'AUTH:errors.authorizationLimitExceeded',
              },
            });
            return;
          } else {
            dispatch({
              type: CaptchaActionTypes.SetIsCaptchaVisible,
              payload: false,
            });

            dispatch({
              type: AuthActionTypes.SetAuthorizeFailState,
              payload: {
                title: t('AUTH:hints.letsTakeBreak'),
                subtitle: t('AUTH:labels.tryAgainLater'),
                refRoute: '/login',
              },
            });

            navigate('/authorize-fail');
          }
        }

        if (error?.response?.data?.code === 'UNKNOWN_USER_OR_CODE') {
          if (!preset) {
            sendAnalyticEvent(analyticEvents.enterOtpFail);
          }
        }
      }

      const resetError = setTimeout(() => setSmsCode(''), resetErrorMs);

      return () => clearTimeout(resetError);
    }
  }, [error]);

  useEffect(() => {
    if (resendError) {
      dispatch({
        type: CaptchaActionTypes.SetCaptchaToken,
        payload: undefined,
      });
      dispatch({
        type: CaptchaActionTypes.SetIsCaptchaVisible,
        payload: false,
      });
      const e = resendError?.response?.status;
      const isRateLimitExceededError =
        resendError?.response?.data?.code === 'RATE_LIMIT_EXCEEDED';

      if (e === 400) {
        const title = isRateLimitExceededError
          ? t('AUTH:hints.letsTakeBreak')
          : t('COMMON:errors.somethingWentWrong');

        const subtitle = isRateLimitExceededError
          ? t('AUTH:labels.tryAgainLater')
          : t('COMMON:hints.tryToRegisterAgainOrWriteToSupport');

        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });

        dispatch({
          type: UserActionTypes.SetCachedPhoneNumber,
          payload: undefined,
        });

        dispatch({
          type: UserActionTypes.SetAuthenticationRefetchUid,
          payload: uuidv4(),
        });

        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title,
            subtitle,
            refRoute: '/login',
          },
        });
        navigate('/authorize-fail');
      }
    }
  }, [resendError]);

  const isShowForm = !isLoading && !isResendLoading && !isRegistrationLoading;

  if (!isShowForm) {
    return <Skeleton />;
  }

  return (
    <BaseLayout>
      {phoneNumber && isShowForm && (
        <PageContent>
          <PageBackButton
            variant="text"
            icon={<ArrowLeftIcon2 />}
            onClick={goBack}
          >
            {t('COMMON:buttons.back')}
          </PageBackButton>
          <PageTitle>{t('AUTH:hints.enterCode')}</PageTitle>
          <PageSubTitle>
            {t('AUTH:hints.sentSMS')} {displayPhoneNumber}
          </PageSubTitle>
          <Otp
            code={smsCode}
            style={{ marginBottom: 32 }}
            gridStep={24}
            alignment={'flex-start'}
            onChange={handleOnChange}
            limit={otpLimit}
            onResend={handleResend}
            expiryTimestamp={expiredTime}
            status={status}
            isExpiryTimestampText
          />
        </PageContent>
      )}
    </BaseLayout>
  );
};
